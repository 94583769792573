import React, { useState, useEffect, useContext } from 'react';
import checkIcon from '../../../images/Vectors/HappyHome_CheckIcon.svg';
import crossIcon from '../../../images/Vectors/HappyHome_CrossIcon.svg';
import AuthContext from '../../../base/components/AuthProvider';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const AdminPanelParentsList = ({ searchTerm }) => {
  const { authTokens } = useContext(AuthContext);
  const [parents, setParents] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParents = async () => {
      try {
        setLoadingData(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/parent-profiles/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setParents(data);
          setError(null); 
        } else {
          console.error('Error al obtener padres:', data.error);
          setError('Error al obtener padres'); 
        }
      } catch (error) {
        console.error('Error al obtener padres:', error);
        setError('Error al obtener padres'); 
      } finally {
        setLoadingData(false);
      }
    };

    fetchParents();
  }, [authTokens.access]);

  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este padre?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/user/${userId}/delete/`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          setParents(parents.filter(parent => parent.user.id !== userId));
          setError(null);
        } else {
          const errorData = await response.json();
          const errorText = errorData.error;
          console.error('Error al eliminar:', errorText);
          setError(`Error al eliminar: ${errorText}`);
        }
      } catch (error) {
        console.error('Error al eliminar:', error.message);
        setError(`Error al eliminar: ${error.message}`);
      }
    }
  };

  const filteredParents = parents.filter(parent =>
    parent.user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-panel-container">
      <div className="admin-panel-error-container">
        {error && <p className="error-message">{error}</p>}
      </div>
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <table className="admin-panel-table">
          <thead>
            <tr>
              <th>Se unió</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Ultima conexión</th>
              <th>Acciones</th>
              <th className="last-column">Membresía</th>
            </tr>
          </thead>
          <tbody>
            <tr className="space-row"><td colSpan="7" style={{ height: '10px' }}></td></tr>
            {filteredParents.map(parent => (
              <tr key={parent.id}>
                <td><div>{parent.user.formatted_created_at}</div></td>
                <td><div>{parent.user.full_name}</div></td>
                <td><div>{parent.user.email}</div></td>
                <td><div>{parent.user.phone ? parent.user.phone : 'N/A'}</div></td>
                <td><div>{parent.user.formatted_last_login}</div></td>
                <td>
                  <div className="buttons-container">
                    <button className="primary-btn" onClick={() => handleDelete(parent.user.id)}>Eliminar</button>
                  </div>
                </td>
                <td>
                  <div className="admin-panel-parent-membership">
                    {parent.membership ? (
                      <>
                        {parent.membership.membership_type.name} 
                        <div className="parent-membership-status">
                          {parent.membership.status ? (
                            <img src={checkIcon} alt="Active" className="icon-svg"/>
                          ) : (
                            <img src={crossIcon} alt="Inactive" className="icon-svg"/>
                          )}
                        </div>
                      </>
                    ) : 'N/A'}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPanelParentsList;
