import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import HappyHomeLogo from '../../../images/Logo/HappyHomeLogo.png';

function DefaultNavbar({ onLoginClick, onSignUpClick }) {
  const [isResponsive, setIsResponsive] = useState(false);
  const location = useLocation();

  const toggleResponsiveMenu = () => {
    setIsResponsive(!isResponsive);
  };

  useEffect(() => {
    setIsResponsive(false);
    window.scrollTo(0, 0);
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className={`navbar ${isResponsive ? 'responsive' : ''}`}>
      {/* 
      <div className="navbar-advice">
        <p>REGISTRO SIN COSTO</p>
      </div>
      */}
      <div className="navbar-content">
        <div className="navbar-left">
          <div className="navbar-left-logo">
            <Link to="/">
              <img src={HappyHomeLogo} alt="Happy Home Logo"></img>
            </Link>
          </div>
        </div>
        <div className="navbar-right-background">
          <div className="navbar-right">
            <Link to="/job-offers" className={`navbar-link ${isActive('/job-offers')}`}>
              Empleos
            </Link>
            <Link to="/workers" className={`navbar-link ${isActive('/workers')}`}>
              Perfiles
            </Link>
            <Link to="/memberships" className={`navbar-link ${isActive('/memberships')}`}>
              Membresias
            </Link>
            <Link to="/faqs" className={`navbar-link ${isActive('/faqs')}`}>
              Preguntas
            </Link>
            <Link to="/contact" className={`navbar-link ${isActive('/contact')}`}>
              Contacto
            </Link>
            <button className="navbar-button" onClick={onLoginClick}>
              Ingresar
            </button>
            <button className="navbar-button secondary" onClick={onSignUpClick}>
              Registrarme
            </button>
          </div>
        </div>
        <button className="navbar-toggle" onClick={toggleResponsiveMenu}>
          <FontAwesomeIcon icon={isResponsive ? faTimes : faBars} />
        </button>
      </div>
    </div>
  );
}

export default DefaultNavbar;

