import React from 'react';

const SignUpVerifyEmailPopUp = ({ onClose }) => {
  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h2>Verificá tu correo</h2>
            <div className="popup-intro-description">
              <p>
                Te enviamos un correo electrónico con un enlace para confirmar tu cuenta. 
                Por favor revisá tu bandeja de entrada (y la carpeta de spam) para completar tu registro.
              </p>
            </div>
          </div>
          <div className="buttons-container">
            <button className="primary-btn" onClick={onClose}>
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpVerifyEmailPopUp;