import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import HappyHomeLogo from '../../../images/Logo/HappyHomeLogo.png';

function WorkerNavbar({ logoutUser }) {
  const [isResponsive, setIsResponsive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const toggleResponsiveMenu = () => {
    setIsResponsive(!isResponsive);
  };

  const handleLogout = () => {
    logoutUser();
    navigate('/', { replace: true });
    window.location.reload();
  };  

  useEffect(() => {
    setIsResponsive(false);
    window.scrollTo(0, 0);
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

  return (
    <div className={`navbar ${isResponsive ? 'responsive' : ''}`}>
      <div className="navbar-content">
        <div className="navbar-left">
          <div className="navbar-left-logo">
            <Link to="/">
              <img src={HappyHomeLogo} alt="Happy Home Logo" />
            </Link>
          </div>
        </div>
        <div className="navbar-right-background">
          <div className="navbar-right">
            <Link to="/job-offers" className={`navbar-link ${isActive('/job-offers')}`}>
              Empleos
            </Link>
            <Link to="/workers" className={`navbar-link ${isActive('/workers')}`}>
              Perfiles
            </Link>
            <Link to="/conversations" className={`navbar-link ${isActive('/conversations')}`}>
              Chat
            </Link>
            <Link to="/faqs" className={`navbar-link ${isActive('/faqs')}`}>
              Preguntas
            </Link>
            <Link to="/contact" className={`navbar-link ${isActive('/contact')}`}>
              Contacto
            </Link>
            <Link to="/user-account" className={`navbar-link ${isActive('/user-account')}`}>
              Mi cuenta
            </Link>
            <button className="navbar-button" onClick={handleLogout}>
              Cerrar sesión
            </button>
          </div>
        </div>
        <button className="navbar-toggle" onClick={toggleResponsiveMenu}>
          <FontAwesomeIcon icon={isResponsive ? faTimes : faBars} />
        </button>
      </div>
    </div>
  );
}

export default WorkerNavbar;
