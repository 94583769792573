import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() => {
    const storedTokens = localStorage.getItem('authTokens');
    return storedTokens ? JSON.parse(storedTokens) : null;
  });
  const [userData, setUserData] = useState(() => {
    const storedTokens = localStorage.getItem('authTokens');
    return storedTokens ? jwtDecode(storedTokens) : null;
  });
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const storedTokens = localStorage.getItem('authTokens');
    return !!storedTokens;
  });
  const [loading, setLoading] = useState(true);

  const loginUser = async (data) => {
    try {
      const storedTokens = localStorage.getItem('authTokens');
      if (storedTokens) {
        return { success: false, message: 'User is already authenticated' };
      } else {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: data.email, password: data.password }),
        });

        const responseData = await response.json();

        if (response.status === 200) {
          setAuthTokens(responseData);
          const decoded_data = jwtDecode(responseData.access);
          setUserData(decoded_data);
          setIsAuthenticated(true);
          localStorage.setItem('authTokens', JSON.stringify(responseData));

          const is_admin = decoded_data.is_admin;
          return { success: true, is_admin: is_admin };
        } else {
          const error_messages = {
            400: "No existe ninguna cuenta con el correo electrónico ingresado.",
            401: "Email o contraseña incorrectos.",
            409: "Ya tienes la sesión iniciada.",
          };
          const error_message = error_messages[response.status] || responseData.error;
          return { success: false, message: error_message };
        }
      }
    } catch (error) {
      const error_message = `Error: ${error}`;
      return { success: false, message: error_message };
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUserData(null);
    setIsAuthenticated(false);
    localStorage.removeItem('authTokens');
  };

  const updateToken = useCallback(async () => {
    if (!authTokens || !authTokens.refresh) {
      console.error('No refresh token available');
      if (loading) {
        setLoading(false);
      }
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/token/refresh/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: authTokens.refresh }),
      });

      if (response.status === 200) {
        const data = await response.json();
        const decoded_data = jwtDecode(data.access);
        setAuthTokens(data);
        setUserData({
          ...decoded_data,
          profile_type: data.profile_type,
          profile_id: data.profile_id,
          ...(data.profile_type === 'Worker' ? { profile_visibility: data.profile_visibility } : {}),
          ...(data.profile_type === 'Parent' ? { 
            membership_type: data.membership_type, 
            membership_status: data.membership_status 
          } : {}),
          is_admin: data.is_admin || false,
        });

        localStorage.setItem('authTokens', JSON.stringify(data));
      } else {
        logoutUser();
      }

      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      logoutUser();
    }
  }, [authTokens, loading]);

  function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }

  const contextData = {
    userData: userData,
    authTokens: authTokens,
    isAuthenticated: isAuthenticated,
    loginUser: loginUser,
    logoutUser: logoutUser,
    getCookie: getCookie,
  };

  useEffect(() => {
    if (loading && isAuthenticated) {
      updateToken();
    }
    const fourMinutes = 1000 * 60 * 4;
    const interval = setInterval(() => {
      if (authTokens) {
        updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [authTokens, loading, isAuthenticated, updateToken]);

  return <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>;
};