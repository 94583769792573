import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfileVisibilityToggle from './UserProfileVisibilityToggle';
import AuthContext from '../../../base/components/AuthProvider';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const AdminPanelWorkersList = ({ searchTerm }) => {
  const { authTokens } = useContext(AuthContext);
  const [workers, setWorkers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkers = async () => {
      try {
        setLoadingData(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/worker-profiles/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setWorkers(data);
        } else {
          console.error('Error al obtener trabajadores:', response.statusText);
        }
      } catch (error) {
        console.error('Error al obtener trabajadores:', error.message);
      } finally {
        setLoadingData(false);
      }
    };

    fetchWorkers();
  }, [authTokens.access]);

  const handleDelete = async (userId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este trabajador?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/user/${userId}/delete/`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          setWorkers(workers.filter(worker => worker.user.id !== userId));
        } else {
          console.error('Error al eliminar al trabajador:', response.statusText);
        }
      } catch (error) {
        console.error('Error al eliminar al trabajador:', error.message);
      }
    }
  };

  const filteredWorkers = workers.filter(worker =>
    worker.user.full_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-panel-container">
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <table className="admin-panel-table">
          <thead>
            <tr>
              <th>Se unió</th>
              <th>Nombre</th>
              <th>Email</th>
              <th>Teléfono</th>
              <th>Ultima conexión</th>
              <th>Acciones</th>
              <th className="last-column">Perfil visible</th>
            </tr>
          </thead>
          <tbody>
            <tr className="space-row"><td colSpan="7" style={{ height: '10px' }}></td></tr>
            {filteredWorkers.map(worker => (
              <tr key={worker.id}>
                <td>{worker.user.formatted_created_at}</td>
                <td><div>{worker.user.full_name}</div></td>
                <td><div>{worker.user.email}</div></td>
                <td><div>{worker.user.phone ? worker.user.phone : 'N/A'}</div></td>
                <td><div>{worker.user.formatted_last_login}</div></td>
                <td>
                  <div className="buttons-container">
                    <button className="primary-btn" onClick={() => handleDelete(worker.user.id)}>Eliminar</button>
                    <button className="secondary-btn" onClick={() => navigate(`/worker-profile/${worker.user.id}`)}>Ver perfil</button>
                  </div>
                </td>
                <td>
                  <UserProfileVisibilityToggle
                    userId={worker.user.id}
                    initialValue={worker.user.profile_visibility}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPanelWorkersList;
