import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../base/components/AuthProvider';
import JobOfferStatusToggle from './JobOfferStatusToggle';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const AdminPanelJobOffersList = ({ searchTerm }) => {
  const { authTokens } = useContext(AuthContext);
  const [jobOffers, setJobOffers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobOffers = async () => {
      try {
        setLoadingData(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/job-offers/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setJobOffers(data);
          setError(null);
        } else {
          console.error('Error al obtener ofertas de trabajo:', data.error);
          setError('Error al obtener ofertas de trabajo');
        }
      } catch (error) {
        console.error('Error al obtener ofertas de trabajo:', error);
        setError('Error al obtener ofertas de trabajo');
      } finally {
        setLoadingData(false);
      }
    };

    fetchJobOffers();
  }, [authTokens.access]);

  const handleDelete = async (jobOfferId) => {
    const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar esta oferta de trabajo?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/job-offer/${jobOfferId}/delete/`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          setJobOffers(jobOffers.filter(jobOffer => jobOffer.id !== jobOfferId));
          setError(null);
        } else {
          const errorData = await response.json();
          const errorText = errorData.error;
          console.error('Error al eliminar:', errorText);
          setError(`Error al eliminar: ${errorText}`);
        }
      } catch (error) {
        console.error('Error al eliminar:', error.message);
        setError(`Error al eliminar: ${error.message}`);
      }
    }
  };

  const filteredJobOffers = jobOffers.filter(jobOffer =>
    jobOffer.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-panel-container">
      <div className="admin-panel-error-container">
        {error && <p className="error-message">{error}</p>}
      </div>
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <table className="admin-panel-table">
          <thead>
            <tr>
              <th>Publicada</th>
              <th>Título</th>
              <th>Empleador</th>
              <th>Provincia</th>
              <th>Partido</th>
              <th>Acciones</th>
              <th className="last-column">Oferta visible</th>
            </tr>
          </thead>
          <tbody>
            <tr className="space-row"><td colSpan="7" style={{ height: '10px' }}></td></tr>
            {filteredJobOffers.map(jobOffer => (
              <tr key={jobOffer.id}>
                <td>{jobOffer.formatted_created_at}</td>
                <td>{jobOffer.title}</td>
                <td>{jobOffer.parent_profile.user.full_name}</td>
                <td>{jobOffer.location.province.name}</td>
                <td>{jobOffer.location.municipality.name}</td>
                <td>
                  <div className="buttons-container">
                    <button className="primary-btn" onClick={() => handleDelete(jobOffer.id)}>Eliminar</button>
                    <button className="secondary-btn" onClick={() => navigate(`/job-offer/${jobOffer.id}`)}>Ver oferta</button>
                  </div>
                </td>
                <td>
                  <JobOfferStatusToggle
                    jobId={jobOffer.id}
                    initialValue={jobOffer.status}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPanelJobOffersList;
