import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../../base/components/AuthProvider';
import LoadingSpinner from '../../../common/components/LoadingSpinner';

const AdminPanelPaymentsList = ({ searchTerm }) => {
  const { authTokens } = useContext(AuthContext);
  const [payments, setPayments] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        setLoadingData(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/payments/get-all/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setPayments(data);
        } else {
          console.error('Error al obtener pagos:', response.statusText);
        }
      } catch (error) {
        console.error('Error al obtener pagos:', error.message);
      } finally {
        setLoadingData(false);
      }
    };

    fetchPayments();
  }, [authTokens.access]);

  return (
    <div className="admin-panel-container">
      {loadingData ? (
        <LoadingSpinner />
      ) : (
        <table className="admin-panel-table">
          <thead>
            <tr>
              <th>Usuario</th>
              <th>Fecha</th>
              <th>Transacción ID</th>
              <th>Método de Pago</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tbody>
          <tr className="space-row"><td colSpan="7" style={{ height: '10px' }}></td></tr>
            {payments
              .filter(payment =>
                (payment.user && payment.user.full_name && payment.user.full_name.toLowerCase().includes(searchTerm.toLowerCase())) ||
                (payment.transaction_id && payment.transaction_id.toLowerCase().includes(searchTerm.toLowerCase()))
              )
              .map(payment => (
                <tr key={payment.id}>
                  <td>{payment.user.full_name}</td>
                  <td>{payment.timestamp}</td>
                  <td>{payment.transaction_id}</td>
                  <td>{payment.payment_method_display}</td>
                  <td>${payment.amount}</td>
                </tr>
              ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPanelPaymentsList;
