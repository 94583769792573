import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AuthContext from '../base/components/AuthProvider';
import DefaultNavbar from '../base/components/Navbar/DefaultNavbar';
import WorkerNavbar from '../base/components/Navbar/WorkerNavbar';
import ParentNavbar from '../base/components/Navbar/ParentNavbar';
import AdminNavbar from '../adminapp/components/Navbar/AdminNavbar';
import ProtectedRouteAdmin from './ProtectedRouteAdmin';
import ProtectedRouteUser from './ProtectedRouteUser';
import ProtectedRouteActiveMembership from './ProtectedRouteActiveMembership';
import HomePage from '../base/pages/HomePage';
import MembershipsPage from '../base/pages/MembershipsPage';
import UserChatPage from '../chat/components/ChatPage/UserChatPage';
import ForgotPassword from '../base/components/ForgotPassword';
import ResetPasswordConfirm from '../base/components/ResetPasswordConfirm';
import EmailVerificationSuccess from '../base/components/EmailVerificationSuccess';
import Login from '../base/components/Login';
import SignUp from '../base/components/SignUp';
import WorkerProfilePage from '../base/pages/WorkerProfilePage';
import JobOfferPage from '../base/pages/JobOfferPage';
import UserAccountPage from '../base/pages/UserAccountPage';
import TermsPage from '../base/pages/TermsPage';
import PrivacyPolicyPage from '../base/pages/PrivacyPolicyPage';
import FAQsPage from '../base/pages/FAQsPage';
import ContactPage from '../base/pages/ContactPage';
import WorkersPage from '../base/pages/WorkersPage';
import JobOffersPage from '../base/pages/JobOffersPage';
import BankTransferPayment from '../base/components/Payment/BankTransferPayment';
import AdminPanelWorkersPage from '../adminapp/pages/AdminPanelWorkersPage';
import AdminPanelParentsPage from '../adminapp/pages/AdminPanelParentsPage';
import AdminPanelJobOffersPage from '../adminapp/pages/AdminPanelJobOffersPage';
import AdminPanelMembershipsPage from '../adminapp/pages/AdminPanelMembershipsPage';
import AdminPanelPaymentsPage from '../adminapp/pages/AdminPanelPaymentsPage';
import AdminPanelStatsPage from '../adminapp/pages/AdminPanelStatsPage';
import NotFoundPage from '../base/pages/NotFoundPage';

const PageRouter = () => {
    const [loginVisible, setLoginVisible] = useState(false);
    const [signUpVisible, setSignUpVisible] = useState(false);
    const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
    const { userData, logoutUser } = useContext(AuthContext);
    const closePopups = () => {
        setLoginVisible(false);
        setSignUpVisible(false);
        setForgotPasswordVisible(false);
    };

    const handleForgotPassword = () => {
        setLoginVisible(false);
        setForgotPasswordVisible(true);
    };

    const NavbarComponent = !userData
        ? DefaultNavbar
        : userData.is_admin
            ? AdminNavbar
            : userData.profile_type === 'Parent'
                ? ParentNavbar
                : WorkerNavbar;

    return (
        <>
            <NavbarComponent
                {...(!userData && { onLoginClick: () => setLoginVisible(true), onSignUpClick: () => setSignUpVisible(true) })}
                profileType={userData ? userData.profile_type : ''}
                logoutUser={logoutUser}
                {...(userData && userData.profile_type === 'Worker'  )}
                {...(userData && userData.profile_type === 'Parent' )}
            />
            <Routes>
                <Route path="/" element={<HomePage setSignUpVisible={setSignUpVisible}/>} />
                <Route path="/reset-password" element={<ForgotPassword onClose={() => setForgotPasswordVisible(false)} />} />
                <Route path="/reset/:uidb64/:token" element={<ResetPasswordConfirm />} />
                <Route path="/email-verification/:uidb64/:token" element={<EmailVerificationSuccess />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
                <Route path="/faqs" element={<FAQsPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/workers" element={<WorkersPage />} />
                <Route path="/job-offers" element={<JobOffersPage />} />
                <Route path="/memberships" element={<MembershipsPage setSignUpVisible={setSignUpVisible} />} />
                <Route path="/job-offer/:jobOfferId" element={<JobOfferPage setSignUpVisible={setSignUpVisible} />} />
                <Route path="/worker-profile/:profileId" element={<WorkerProfilePage setSignUpVisible={setSignUpVisible} />} />
                <Route element={<ProtectedRouteUser />}>
                    <Route path="/user-account" element={<UserAccountPage />} />
                </Route>

                <Route element={<ProtectedRouteActiveMembership />}>
                    <Route path="/conversations/*" element={<UserChatPage />} />
                    <Route path="/conversations/chat/:conversationId" element={<UserChatPage />} />
                </Route>
                <Route element={<ProtectedRouteUser />}>
                    <Route path="/membership-type/:membershipTypeId/bank-transfer/payment" element={<BankTransferPayment />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/workers" element={<AdminPanelWorkersPage />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/parents" element={<AdminPanelParentsPage />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/job-offers" element={<AdminPanelJobOffersPage />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/memberships" element={<AdminPanelMembershipsPage />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/payments" element={<AdminPanelPaymentsPage />} />
                </Route>
                <Route element={<ProtectedRouteAdmin />}>
                    <Route path="/admin/stats" element={<AdminPanelStatsPage />} />
                </Route>
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            {loginVisible && <Login onClose={closePopups} onForgotPassword={handleForgotPassword} />}
            {signUpVisible && <SignUp onClose={closePopups} />}
            {forgotPasswordVisible && <ForgotPassword onClose={closePopups} />}
        </>
    );
};

export default PageRouter;
