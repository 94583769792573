import React, { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthContext from '../AuthProvider';
import PaymentSuccess from './PaymentSuccess';

const BankTransferPayment = () => {
  const navigate = useNavigate();
  const { membershipTypeId } = useParams();
  const { userData, authTokens } = useContext(AuthContext);
  const [membership, setMembership] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    const fetchMembership = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/membership-type/${membershipTypeId}/get/`, {
          headers: {
            'Authorization': `Bearer ${authTokens.access}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setMembership(data);
        } else {
          console.error('Error al recuperar la membresía:', response.statusText);
        }
      } catch (error) {
        console.error('Error al recuperar la membresía:', error.message);
      }
    };

    fetchMembership();
  }, [membershipTypeId, authTokens.access]);

  const handleConfirmTransfer = async () => {
    if (!membership) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/membership/${membership.id}/create/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authTokens.access}`,
        },
        body: JSON.stringify({
          payment: {
            amount: membership.price,
            payment_method: 'bank_transfer',
          }
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setPaymentDetails(data);
        setIsSuccess(true);
      } else {
        console.error('Error al adquirir la membresía:', response.statusText);
      }
    } catch (error) {
      console.error('Error al adquirir la membresía:', error.message);
    }
  };

  if (!membership) {
    return <div>Cargando...</div>;
  }

  return (
    <div>
      {isSuccess ? (
        <PaymentSuccess paymentDetails={paymentDetails} />
      ) : (
        <>
          <div className="payment-method-container">
            <div className="section-title">
              <h2>Transferencia Bancaria</h2>
              <p>Realiza la transferencia a nuestra cuenta bancaria y haz clic en confirmar para adquirir tu membresía.</p>
            </div>
            <div className="payment-details-section">
              <h2 className="profile-edit-section-title">CUENTA BANCARIA</h2>
              <div className="payment-details-container">
                <p><strong>Banco: </strong>Mercado Pago</p>
                <p><strong>ALIAS: </strong>Happy.home</p>
                <p><strong>CVU: </strong>0000003100033439078348</p>
                <p><strong>Importe: </strong>${membership.price}</p>
                <p><strong>Membresía: </strong>{membership.name}</p>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" onClick={handleConfirmTransfer}>Confirmar</button>
                <button className="secondary-btn" onClick={() => navigate('/memberships')}>Volver atrás</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BankTransferPayment;
